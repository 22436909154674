import React, { ReactElement, useState } from 'react'
import TabTitle from './TabTitle'

type Props = {
  children: ReactElement[]
}

const Tabs: React.FC<Props> = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <div>
      <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
        {children.map((item, index) => (
          <TabTitle
            key={index}
            active={index === selectedTab}
            title={item.props.title}
            index={index}
            setSelectedTab={setSelectedTab}
          />
        ))}
      </ul>
      <div className="card mb-5 mb-xl-10">
        {React.Children.map(children, child => {
          if (React.isValidElement(child)) {
            return (
              <div
                style={{
                  display: child.key === String(selectedTab) ? 'block' : 'none'
                }}
              >
                {React.cloneElement(child)}
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}

export default Tabs
