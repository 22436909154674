import React, { useEffect } from 'react'
import backgroundImage from '../../assets/media/illustrations/sketchy-1/14.png'
import logoMetronic from '../../assets/media/logos/logo-1.svg'
import logoGoogle from '../../assets/media/svg/brand-logos/google-icon.svg'
import logoFacebook from '../../assets/media/svg/brand-logos/facebook-4.svg'
import logoApple from '../../assets/media/svg/brand-logos/apple-black.svg'

const SignUp: React.FC = () => {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])
  return (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{
        backgroundImage: `url(${backgroundImage})`
      }}
    >
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        <a href="#" className="mb-12">
          <img alt="Logo" src={logoMetronic} className="h-45px" />
        </a>
        <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
          <form
            className="form w-100"
            // onSubmit={formik.handleSubmit}
            noValidate
            id="kt_login_signin_form"
          >
            <div className="text-center mb-10">
              <h1 className="text-dark mb-3">Sign In to Metronic</h1>
              <div className="text-gray-400 fw-bold fs-4">
                New Here?{' '}
                <a href="#" className="link-primary fw-bolder">
                  Create an Account
                </a>
              </div>
            </div>
            <div className="fv-row mb-10">
              <label className="form-label fs-6 fw-bolder text-dark">
                Email
              </label>
              <input
                placeholder="Email"
                className="form-control form-control-lg form-control-solid"
                type="email"
                name="email"
                autoComplete="off"
              />
            </div>

            <div className="fv-row mb-10">
              <div className="d-flex justify-content-between mt-n5">
                <div className="d-flex flex-stack mb-2">
                  <label className="form-label fw-bolder text-dark fs-6 mb-0">
                    Password
                  </label>
                </div>
              </div>
              <input
                type="password"
                autoComplete="off"
                className="form-control form-control-lg form-control-solid"
              />
            </div>

            <div className="text-center">
              <button
                type="submit"
                id="kt_sign_in_submit"
                className="btn btn-lg btn-primary w-100 mb-5"
                // disabled={formik.isSubmitting || !formik.isValid}
              >
                <span className="indicator-label">Continue</span>
              </button>

              <div className="text-center text-muted text-uppercase fw-bolder mb-5">
                or
              </div>

              <a
                href="#"
                className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
              >
                <img alt="Logo" src={logoGoogle} className="h-20px me-3" />
                Continue with Google
              </a>

              <a
                href="#"
                className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
              >
                <img alt="Logo" src={logoFacebook} className="h-20px me-3" />
                Continue with Facebook
              </a>

              <a
                href="#"
                className="btn btn-flex flex-center btn-light btn-lg w-100"
              >
                <img alt="Logo" src={logoApple} className="h-20px me-3" />
                Continue with Apple
              </a>
            </div>
          </form>
        </div>
      </div>
      <div className="d-flex flex-center flex-column-auto p-10">
        <div className="d-flex align-items-center fw-bold fs-6">
          <a href="#" className="text-muted text-hover-primary px-2">
            About
          </a>

          <a href="#" className="text-muted text-hover-primary px-2">
            Contact
          </a>

          <a href="#" className="text-muted text-hover-primary px-2">
            Contact Us
          </a>
        </div>
      </div>
    </div>
  )
}

export default SignUp
